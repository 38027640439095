.homepage-footer-box-sec {
  margin-top: 300px;
  margin-bottom: 80px;
}

.homepage-footer-box-sec .we-do-row .slide {
  padding-left: 10px;
  padding-right: 10px;
}


.bottom-arrow-btn {
    position: fixed;
    bottom: 30px;
    left: calc(50% - 20px);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    transform: rotate(180deg);
}

.bottom-arrow-btn.show {
    visibility: visible;
    opacity: 1;
    transform: rotate(0deg);
}

.bottom-arrow-btn i{
    font-size: 40px;
    color: #fff;
    text-shadow: 0 0 10px rgb(134, 134, 134);
}



.homepage-footer-box-sec .we-do-row .we-do-box {
    border: 1px solid var(--my-blue);
    border-radius: 15px;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
    height: 95%;
    overflow: hidden;
}

.homepage-footer-box-sec .we-do-row .we-do-box img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.homepage-footer-box-sec .we-do-row .we-do-box h3 {
    font-size: 22px;
    color: var(--my-blue);
    margin-top: 15px;
    padding: 0 20px;
    font-family: 'ab';
}

.homepage-footer-box-sec .we-do-box .hover-content {
    position: absolute;
    background-color: var(--my-blue);
    width: 100%;
    height: 100%;
    border-radius: 14px;
    padding: 30px 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    height: 0;
    bottom: 0;
}

.homepage-footer-box-sec .we-do-row .we-do-box:hover .hover-content {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    height: 100%;
    pointer-events: all;
}

.homepage-footer-box-sec .we-do-box .hover-content img {
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 20px;
}

.homepage-footer-box-sec .we-do-box .hover-content h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
    font-family: 'ah';
}

.homepage-footer-box-sec .we-do-box .hover-content i {
    font-size: 8px;
    color: #fff;
    margin: 10px 0;
}

.homepage-footer-box-sec .we-do-box .hover-content .dot {
    font-size: 28px;
    color: #fff;
    margin: -7px 0;
}


@media (max-width: 991px) {
 .homepage-footer-box-sec .we-do-row .we-do-box img {
      width: 90px;
      height: 90px;
  }
 .homepage-footer-box-sec .we-do-box .hover-content {
      display: block;
      position: relative;
      visibility: visible;
      height: 100%;
      opacity: 1;
      background-color: #fff;
  }
 .homepage-footer-box-sec .we-do-box .hover-content h4 {
      color: var(--my-blue);
  }
 .homepage-footer-box-sec .we-do-box .hover-content i {
      color: var(--my-blue);
  }
 .homepage-footer-box-sec .we-do-box .hover-content img {
      display: none;
  }
  .homepage-footer-box-sec .we-do-row .slide {
    padding-left: 15px;
    padding-right: 15px;
  }
  
}