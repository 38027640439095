.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.loading-container img {
    width: 180px;
    height: auto;
}