@font-face {
    font-family: 'ab';
    src: local('ab'), url('./assets/fonts/Avenir-Black.ttf'), url('./assets/fonts/Avenir-Black.woff'), url('./assets/fonts/Avenir-Black.eot');
}


@font-face {
    font-family: 'ar';
    src: local('ar'), url('./assets/fonts/Avenir-Roman.ttf'), url('./assets/fonts/Avenir-Roman.woff'), url('./assets/fonts/Avenir-Roman.eot');
}

@font-face {
    font-family: 'ah';
    src: local('ah'), url('./assets/fonts/Avenir-Heavy.ttf'), url('./assets/fonts/Avenir-Heavy.woff'), url('./assets/fonts/Avenir-Heavy.eot');
}

@font-face {
    font-family: 'am';
    src: local('am'), url('./assets/fonts/Avenir-Medium.ttf'), url('./assets/fonts/Avenir-Medium.woff'), url('./assets/fonts/Avenir-Medium.eot');
}

@font-face {
    font-family: 'al';
    src: local('al'), url('./assets/fonts/Avenir-Light.ttf'), url('./assets/fonts/Avenir-Light.woff'), url('./assets/fonts/Avenir-Light.eot');
}

* {
    margin: 0;
    padding: 0;
    font-family: 'ar';
    box-sizing: border-box;
}

.toast-style button:focus {
    outline: none !important;
}