.footer-sec {
    padding: 18px 0;
    background-color: var(--my-blue);
    border-top: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(130deg, var(--my-blue) 50%, var(--my-orange));
}

.inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-menu-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
    padding: 0;
}

.footer-menu-links li a {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    padding: 0 13px;
    border-right: 1px solid #fff;
    transition: all 0.3s ease-in-out;
    font-family: 'ar';
    text-decoration: none;
}

.footer-menu-links li.active a {
    color: var(--my-orange);
}

.footer-menu-links li:last-child a {
    border-right: none;
}

.footer-menu-links li a:hover {
    color: var(--my-orange);
}

.footer-sec .right-sec {
    display: flex;
    align-items: center;
}

.footer-sec .right-sec .copyright-sec p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 30px;
    font-family: 'ar';
}

.footer-sec .right-sec img {
    max-width: 140px;
}

@media (max-width: 767px) {
    .footer-sec .inner-container {
        flex-direction: column-reverse;
        justify-content: center;
    }
    .footer-sec .right-sec {
        flex-direction: column-reverse;
        justify-content: center;
    }
    .footer-sec .right-sec .copyright-sec p {
        margin: 15px 0;
    }
    .footer-sec {
        border-image-source: linear-gradient(130deg, var(--my-blue) 30%, var(--my-orange));
    }
}

@media (max-width: 467px) {
    .footer-sec {
        padding: 20px 0;
    }
    .footer-menu-links li a {
        font-size: 14px;
        padding: 0 10px;
    }

    .footer-menu-links li:first-child a {
        padding-left: 0;
    }
    
    .footer-menu-links li:last-child a {
        padding-right: 0;
    }
}