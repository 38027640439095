        @media (min-width: 1200px) {
            .container {
                max-width: 1170px;
            }
        }
        
        @media (max-width: 1199px) {
            .container {
                max-width: 1200px;
            }
        }
        
        @media (max-width: 991px) {
         
        }
        
        @media (max-width: 767px) {
           h1 {
            font-size: 35px;
           }
        }
        
        @media (max-width: 600px) {
            .section-heading {
                font-size: 30px;
                margin-bottom: 30px;
                padding: 0 15px;
            }
        }
        
        @media (max-width: 467px) {
            .section-heading {
                margin-bottom: 20px;
                font-size: 26px;
            }
            h1 {
                font-size: 28px;
            }
            h1::before {
                height: 2px;
                max-width: 150px;
            }
        }